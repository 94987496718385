<template lang="">
  <Layout>
    <template v-if="this.$route.name == 'add-plans'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label>Name <span style="color: red">*</span></label>
                <b-form-input
                  id="input-1"
                  placeholder="Enter Name"
                  v-model="form.name"
                  :class="{
                    'is-invalid': submitted && $v.form.name.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.name.required"
                  class="invalid-feedback"
                >
                  name is required.
                </div>
              </b-form-group>

              <b-form-group class="col-6">
                <label for="input-multi"
                  >Product <span style="color: red">*</span></label
                >
                <b-form-select
                  v-model="form.product_id"
                  :options="products"
                  value-field="id"
                  text-field="name"
                  placeholder="select option"
                  :class="{
                    'is-invalid': submitted && $v.form.product_id.$error,
                  }"
                >
                </b-form-select>
                <div
                  v-if="submitted && !$v.form.product_id.required"
                  class="invalid-feedback"
                >
                  product_id is required.
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label-for="input-3"
                class="col-6"
              >
                <label>price <span style="color: red">*</span></label>
                <b-form-input
                  id="input-3"
                  type="number"
                  placeholder="Enter price"
                  v-model="form.price"
                  :class="{
                    'is-invalid': submitted && $v.form.price.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.price.required"
                  class="invalid-feedback"
                >
                  price is required.
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-4"
                label-for="input-4"
                class="col-6"
              >
                <label>slug <span style="color: red">*</span></label>
                <b-form-input
                  id="input-4"
                  placeholder="Enter slug"
                  v-model="form.slug"
                  :class="{
                    'is-invalid': submitted && $v.form.slug.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.slug.required"
                  class="invalid-feedback"
                >
                  slug is required.
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-5"
                label-for="input-5"
                class="col-6"
              >
                <label>credits <span style="color: red">*</span></label>
                <b-form-input
                  id="input-5"
                  type="number"
                  placeholder="Enter credits"
                  v-model="form.credits"
                  :class="{
                    'is-invalid': submitted && $v.form.credits.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.credits.required"
                  class="invalid-feedback"
                >
                  credits is required.
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-6"
                label-for="input-6"
                class="col-6"
              >
                <label
                  >validity in days <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-6"
                  type="number"
                  placeholder="Enter days"
                  v-model="form.validity_in_days"
                  :class="{
                    'is-invalid': submitted && $v.form.validity_in_days.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.validity_in_days.required"
                  class="invalid-feedback"
                >
                  validity_in_days is required.
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label>Currency <span style="color: red">*</span></label>

                <b-form-select
                  id="type"
                  v-model="form.currency"
                  :class="{
                    'is-invalid': submitted && $v.form.currency.$error,
                  }"
                >
                  <option value="INR">INR</option>
                  <option value="IDR">IDR</option>
                  <div
                    v-if="submitted && !$v.form.currency.required"
                    class="invalid-feedback"
                  >
                    country is required.
                  </div>
                </b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label>Sort By <span style="color: red">*</span></label>
                <b-form-input
                  id="input-1"
                  placeholder="Enter Value"
                  v-model="form.sort_by"
                  type="number"
                  :class="{
                    'is-invalid': submitted && $v.form.sort_by.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.sort_by.required"
                  class="invalid-feedback"
                >
                  sort_by is required.
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label>description <span style="color: red">*</span></label>
                <b-form-textarea
                  id="message"
                  v-model="form.description"
                  placeholder="Enter description"
                  rows="4"
                  :class="{
                    'is-invalid': submitted && $v.form.description.$error,
                  }"
                >
                </b-form-textarea>
                <div
                  v-if="submitted && !$v.form.description.required"
                  class="invalid-feedback"
                >
                  description is required.
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-7"
                label-for="input-7"
                class="col-12"
              >
                <b-form-checkbox
                  id="input-7"
                  @input="changestatus"
                  v-model="form.status"
                  >is active</b-form-checkbox
                >
              </b-form-group>

              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                  ><span v-if="this.$route.name == 'add-plans'">Save Data</span>
                  <span v-else>Update Data</span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import gatwayMixin from "../../../../mixins/ModuleJs/plans";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [MixinRequest, gatwayMixin],
  data() {
    return {
      title1: "ADD PLANS",
      title2: "EDIT PLANS",
      items: [
        {
          text: "Back",
          href: "/plans",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  validations: {
    form: {
      name: { required },
      product_id: { required },
      price: { required },
      slug: { required },
      validity_in_days: { required },
      credits: { required },
      currency: { required },
      description: { required },
      sort_by: { required },
    },
  },
};
</script>

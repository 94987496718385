var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-plans')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"email",staticClass:"row",attrs:{"enctype":"multipart/form-data"}},[_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',[_vm._v("Name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.name.$error,
                },attrs:{"id":"input-1","placeholder":"Enter Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.submitted && !_vm.$v.form.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" name is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"input-multi"}},[_vm._v("Product "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-select',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.product_id.$error,
                },attrs:{"options":_vm.products,"value-field":"id","text-field":"name","placeholder":"select option"},model:{value:(_vm.form.product_id),callback:function ($$v) {_vm.$set(_vm.form, "product_id", $$v)},expression:"form.product_id"}}),(_vm.submitted && !_vm.$v.form.product_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" product_id is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-3","label-for":"input-3"}},[_c('label',[_vm._v("price "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.price.$error,
                },attrs:{"id":"input-3","type":"number","placeholder":"Enter price"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),(_vm.submitted && !_vm.$v.form.price.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" price is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-4","label-for":"input-4"}},[_c('label',[_vm._v("slug "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.slug.$error,
                },attrs:{"id":"input-4","placeholder":"Enter slug"},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}}),(_vm.submitted && !_vm.$v.form.slug.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" slug is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-5","label-for":"input-5"}},[_c('label',[_vm._v("credits "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.credits.$error,
                },attrs:{"id":"input-5","type":"number","placeholder":"Enter credits"},model:{value:(_vm.form.credits),callback:function ($$v) {_vm.$set(_vm.form, "credits", $$v)},expression:"form.credits"}}),(_vm.submitted && !_vm.$v.form.credits.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" credits is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-6","label-for":"input-6"}},[_c('label',[_vm._v("validity in days "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.validity_in_days.$error,
                },attrs:{"id":"input-6","type":"number","placeholder":"Enter days"},model:{value:(_vm.form.validity_in_days),callback:function ($$v) {_vm.$set(_vm.form, "validity_in_days", $$v)},expression:"form.validity_in_days"}}),(_vm.submitted && !_vm.$v.form.validity_in_days.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" validity_in_days is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',[_vm._v("Currency "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-select',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.currency.$error,
                },attrs:{"id":"type"},model:{value:(_vm.form.currency),callback:function ($$v) {_vm.$set(_vm.form, "currency", $$v)},expression:"form.currency"}},[_c('option',{attrs:{"value":"INR"}},[_vm._v("INR")]),_c('option',{attrs:{"value":"IDR"}},[_vm._v("IDR")]),(_vm.submitted && !_vm.$v.form.currency.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" country is required. ")]):_vm._e()])],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',[_vm._v("Sort By "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.sort_by.$error,
                },attrs:{"id":"input-1","placeholder":"Enter Value","type":"number"},model:{value:(_vm.form.sort_by),callback:function ($$v) {_vm.$set(_vm.form, "sort_by", $$v)},expression:"form.sort_by"}}),(_vm.submitted && !_vm.$v.form.sort_by.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" sort_by is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',[_vm._v("description "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-textarea',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.description.$error,
                },attrs:{"id":"message","placeholder":"Enter description","rows":"4"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),(_vm.submitted && !_vm.$v.form.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" description is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-12",attrs:{"id":"input-group-7","label-for":"input-7"}},[_c('b-form-checkbox',{attrs:{"id":"input-7"},on:{"input":_vm.changestatus},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_vm._v("is active")])],1),_c('div',{staticClass:"col-12"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-plans')?_c('span',[_vm._v("Save Data")]):_c('span',[_vm._v("Update Data")])])],1)],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }